<template>
  <v-menu 
    v-model="view.toggle"
    :close-on-content-click="false"
    :disabled="!hasMenu"
    offset-y
    max-width="320"
    class="brand-filter"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-hover
        v-slot="{ hover }"
      >
        <v-btn
          depressed
          text
          tile
          height="inherit"
          v-bind="attrs"
          v-on="on"
          :class="{ 'active': hover }"
          class="brands-menu my-n1 px-4 ml-1 mr-2 transparent justify-center"
        >
          <img
            v-if="logo!=null"
            :src="logo"
            ref="logo"
            class="logo align-self-center flex-grow-0"
          >
          <h2 
            v-else
            class="text-button font-weight-bold grey--text text--darken-2"
          >
            {{ title }}
          </h2>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-show="!report&&hasAuthorization.edit"
                icon
                plain
                color="grey"
                small
                class="ml-2 mr-n1"
                v-bind="attrs"
                v-on="on"
                @click.stop="manage(selected)"
              >
                <v-icon dense>
                  {{ icons.config }}
                </v-icon>
              </v-btn>
            </template>
            {{ $t('general.manage') }} {{ title }}
          </v-tooltip>
        </v-btn>
      </v-hover>
    </template>
    <v-card 
      min-width="240"
      class="brand-list py-2"
    >
      <v-text-field
        v-if="isLong"
        v-model="view.search.value"
        :loading="view.search.loading"
        :disabled="view.search.disabled"
        :error-messages="view.search.messages"
        :prepend-inner-icon="icons.search"
        :label="$t(view.search.label)"
        :hint="view.search.hint"
        solo
        flat
        hide-details
        clearable
        name="brand-search"
        autocomplete="off"
        ref="search"
        class="py-1 mt-n2"
        @keyup.esc="view.search.value=''"
      />
      <v-divider 
        v-if="isLong" 
        class="my-0"
      />
      <v-progress-linear
        :active="loading"
        indeterminate
        height="2"
        color="primary"
        class="loading faded"
      />
      <v-list 
        v-show="!empty"
        max-height="320"
        max-width="320"
        class="scrollable py-0"
      >
        <v-list-item
          v-for="item in filteredItems"
          :key="'brand-'+item.id"
          @click="select(item.id)"
        >
          <v-list-item-content>
            <v-list-item-title class="text-overline">{{ item.title }}</v-list-item-title>
          </v-list-item-content>
          <v-list-item-action
            v-if="hasAuthorization.edit"
          >
            <v-btn
              icon
              small
              color="grey"
              @click="manage(item.id)"
            >
              <v-icon size="20">{{ icons.config }}</v-icon>
            </v-btn>
          </v-list-item-action>
        </v-list-item>
      </v-list>
      <v-divider v-if="hasAuthorization.create&&!empty" />
      <v-btn
        v-if="hasAuthorization.create"
        text
        block
        tile
        large
        depressed
        color="primary"
        class="px-4 mt-2"
        @click="create"
      >
        <v-icon 
          left
          size="20"
        >{{ icons.add }}</v-icon>
        <span class="text-overline ml-1">{{ createHint }}</span>
        <v-spacer />
      </v-btn>
    </v-card>
  </v-menu>
</template>

<style>

  .brand-list .v-input__prepend-inner {
    padding-right: 8px !important;
  }
  
</style>

<script>
  import { 
    mdiMenuDown,
    mdiMagnify,
    mdiCog,
    mdiPlus,
  } from '@mdi/js';

  export default {
    props: {
      brands: {
        type: Object,
        default: () => {}
      },
      loading: {
        type: Boolean,
        default: false
      },
      selected: {
        type: Number,
        default: null
      },
      report: {
        type: Boolean,
        default: false
      },
      roles: {
        type: Array,
        default: () => []
      },
    },

    data: () => ({
      icons: {
        menu: mdiMenuDown,
        search: mdiMagnify,
        config: mdiCog,
        add: mdiPlus,
      },
      authorization: {
        create: [1,5,17],
        edit: [1,5,17],
      },
      view: {
        search: {
          value: '',
          default: '',
          loading: false,
          disabled: false,
          label: 'brands.search',
          messages: []
        },
        toggle: false
      }
    }),

    computed: {
      filteredItems () {
        const k = this.view.search.value;
        const key = (typeof k != 'undefined' && k != null) ? new RegExp(k.normalize('NFD').replace(/\p{Diacritic}/gu, ''), 'gi') : new RegExp('', 'gi');
        const items = _.filter(this.brands, (item, id) => {
          if (item==null) console.log(id);
          return k != '' ? key.test(item.title.normalize('NFD').replace(/\p{Diacritic}/gu, '')) : true;
        });
        return _.orderBy(_.map(items, item => {
          return {
            ...item,
            title: item.title.toUpperCase()
          }
        }), ['title'], ['asc']);
      },
      hasMenu () {
        return this.hasAuthorization.create || this.multiple;
      },
      isLong () {
        return _.size(this.brands) > 5;
      },
      multiple () {
        return _.size(this.brands) > 1;
      },
      empty () {
        return _.size(this.filteredItems) == 0;
      },

      title () {
        const stored = this.selected;
        const brands = this.brands;
        const brand = _.isNil(stored)||!_.has(brands, stored) ? null : brands[stored];
        const hasLogo = this.hasLogo;
        return !_.isNil(brand) ? brand.title : this.$t('brands.title');
      },

      hasLogo () {
        return !_.isNil(this.selected) && _.has(this.brands, this.selected) && (!_.isNil(this.brands[this.selected].logotype) && !_.isEmpty(this.brands[this.selected].logotype));
      },
      logo () {
        console.log(this.brands[this.selected]);
        return this.hasLogo ? this.brands[this.selected].logotype : null;
      },


      hasAuthorization () {
        const roles = this.roles;
        const authorization = this.authorization;
        const grants = _.mapValues(authorization, action => {
          return _.intersection(action, roles)>0;
        })
        return grants;
      },
      createHint () {
        return this.$t('general.add') + ' ' + (this.view.search.value==''||this.view.search.value==null ? ' ' + this.$tc('general.brand') : this.view.search.value);
      }
    },

    methods: {
      manage (id) {
        this.$emit('manage', id);
        this.toggle(false);
      },
      create () {
        this.$emit('create', this.view.search.value);
        this.toggle(false);
      },
      select (brand) {
        console.log(brand);
        this.$emit('select', brand);
        this.toggle(false);
      },
      toggle (b) {
        setTimeout($ => {
          $.view.toggle = b;
        }, 250, this, b)
      }
    },

    mounted () {
      
    }
  }
</script>